import { eventPagePayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../../types";

export interface ProductMarketingContentViewedPageEventPayload
  extends EventPayload {
  products: {
    name: string;
    size: string;
    price: string;
    priceCurrency: string;
    styleColor: string;
  };
}

export const createProductMarketingContentViewedPageEventPayload = (
  eventData: EventData,
) => {
  return eventPagePayloadCreator<ProductMarketingContentViewedPageEventPayload>(
    eventData,
  );
};
